<template>
  <div class="housedetail">
    <!-- 轮播图 -->
    <div class="detail-top">
      <van-swipe class="my-swipe" indicator-color="#fff">
        <van-swipe-item class="video-box" v-if="houseVedio">
          <div>
            <van-cell @click="lookVideo"><img :src="swipeList[0]" alt="">
              <div class="icon-center">
                <van-icon name="play-circle-o" />
              </div>
            </van-cell>
          </div>
        </van-swipe-item>
        <van-swipe-item v-for="(item,index) in swipeList" :key="index" @click="goAlbum">
          <img :src="item" alt="" />
        </van-swipe-item>

        <van-swipe-item v-if="swipeList && swipeList.length == 0" @click="goAlbum">
          <img :src="require('../../assets/images/placeholder1.png')" alt="" />
        </van-swipe-item>
      </van-swipe>

      <div class="icon-box">
        <div class="icon-item" @click="goBack">
          <van-icon name="arrow-left" />
        </div>
        <div class="icon-right">
          <div class="icon-item" @click="collectClick" v-if="collect">
            <van-icon name="star" color="#E03E3F" />
          </div>
          <div class="icon-item" @click="collectClick" v-else>
            <van-icon name="star-o" />
          </div>

          <div class="icon-item">
            <van-icon name="share-o" @click="share = true" />
          </div>

          <div class="mcover" v-if="share"  @click="share = false">
            <img :src="require('../../assets/images/share.png')">
          </div>

        </div>
      </div>
      <div class="browse"> {{ updateTime }}{{ item01.viewCount }}人浏览</div>
    </div>
    <div class="video-content" @click="onChange">
      <van-popup v-model="showVideo">
        <!-- <van-icon name="cross" @click="showVideo=false"/> -->
        <video :src="houseVedio" controls ref="houseVedio"></video>
      </van-popup>
    </div>

    <!-- 楼盘信息 -->
    <div class="detail-middle">
      <div class="middle-name">
        <span>{{ item01.title }}</span>
        <span v-if="item01.finish">{{ item01.finish }}</span>
        <span v-if="item01.orientation">{{ item01.orientation}}</span>
        <span v-if="item01.floor">{{ item01.floor}}层</span>
      </div>
      <div class="middle-msg">
        <van-grid :column-num="3" :border="false">
          <van-grid-item>
            <p>售价</p>
            <span>{{ item01.price==0?'面议':item01.price+'万' }}</span>
          </van-grid-item>
          <van-grid-item>
            <p>房型</p>
            <span>{{ item01.houseType? item01.houseType.replace(",", "室").replace(",", "厅") + "卫" : '' }}</span>
          </van-grid-item>
          <van-grid-item>
            <p>建筑面积</p>
            <span>{{ item01.houseArea }}m²</span>
          </van-grid-item>
        </van-grid>
      </div>

      <div class="middle-content">
        <div class="middle-label" v-if="labelList[0]">
          <span v-for="(item, index) in labelList" :key="index">{{item}}</span>
        </div>
        <ul class="middle-inner">
          <li>
            <em>朝向</em>：
            <span>{{ item01.orientation }}</span>
          </li>
          <li>
            <em>楼层</em>：
            <span v-if="item01.floor">{{ item01.floor }}层</span>
          </li>
          <li>
            <em>电梯</em>：
            <span>{{ item01.hasElevator? "有" : "无" }}</span>
          </li>
          <li>
            <em>装修</em>：
            <span>{{ item01.finish }}</span>
          </li>
          <li>
            <em>年代</em>：
            <span v-if=" item01.decade">{{ item01.decade }}年</span>
          </li>
          <li>
            <em>用途</em>：
            <span>{{ item01.application }}</span>
          </li>
          <li>
            <em>权属</em>：
            <span>{{ item01.ownership }}</span>
          </li>
          <li>
            <em>首付</em>：
            <span v-if="item01.downPayment >= 0">{{ item01.payment == 0 ? '不支持按揭':item01.downPayment+'万' }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 服务协议弹窗 -->
    <van-popup v-model="showAgree" class="agree" :overlay-style="{ background: '#fff' }">
      <div class="agree-content" v-html="$xss(agree)"></div>
    </van-popup>
    <div class="agree-bottom" v-if="showAgree">
      <van-checkbox v-model="checked" checked-color="#EC5B5B" icon-size="16px">我已阅读并同意《服务协议》</van-checkbox>
      <div class="agree-btn" @click="closeAgreePopup">我已阅读</div>
    </div>
    <!-- 位置及周边 -->
    <div class="detail-map">
      <div class="item-title">
        <div class="title-left">位置及周边</div>
      </div>
      <div v-if="center.lat">
        <baidu-map class="bm-view" ak="3m0AqdZmu1466T4OmwHbwhGSCBFEAGrM" :center="center" :dragging="dragging" :zoom="zoom" :min-zoom="1" :maxn-zoom="19" @touchmove="touchmove" @touchend="touchend">
          <bm-info-window :position="center" :title="item01.title" :show="showInfo" :width="220" :height="0" :offset="{width:0,height:25}" @close="showInfo = false">
            <p>{{item01.address}}</p>
          </bm-info-window>
          <bm-overlay pane="labelPane" style="position: relative;" @draw="draw">
            <div class="sample" @click="showInfo = true">
              <img src="../../assets/images/dws.svg" alt="">
            </div>
          </bm-overlay>
        </baidu-map>
      </div>

    </div>
    <!-- 房源介绍 -->
    <div class="detail-introduce">
      <div class="item-title">
        <div class="title-left">房源介绍</div>
      </div>
        <div class="introduce-box" v-html="$xss(item01.content)" style=" overflow: hidden" ref="introduce"></div>

    </div>
    <!-- 周边房源 -->
    <div class="detail-periphery">
      <div class="item-title">
        <div class="title-left">周边房源</div>
        <div class="more" @click="gohouseList">更多
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="box-item" v-for="(item,index) in houseList" :key="item.id" @click="goBuildingDetail(item.id)">
        <div class="box-content" v-if="index < 3">
          <house-item :item="item" />
        </div>
      </div>
    </div>

    <!-- 免责内容 -->
    <div class="detail-annotation">
      <div class="annotation-content" v-html="$xss(annotation)"></div>
      <div class="copyright">
        <a :href="copyrightHref" target="_back">{{ copyright }}</a>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="detail-bottom">
      <div class="bottom-btn" @click="orderhouse">预约看房</div>
    </div>
  </div>
</template>
<script>
import houseItem from "../../components/HouseItem.vue";
import { BaiduMap, BmInfoWindow, BmOverlay } from "vue-baidu-map";
import { escapeHtml } from "../../common/validate";


export default {
  name: "SecondHouseDetail",
  data() {
    return {
      center: {},
      zoom: 13,
      item01: {},
      swipeList: [],
      collect: false,
      updateTime: "",
      houseList: [],
      labelList: [],
      share:false,
      houseVedio: "",
      annotation: "",
      copyright: "",
      copyrightHref: "",
      showAgree: false,
      showInfo: true,
      dragging: false,
      showIntroduce: false,
      agree: "",
      showVideo: false,
    };
  },

  created() {
    // 获取传递过来的id
    this.id = this.$route.query.id;
  
  },

  methods: {
    draw({ el, BMap, map }) {
      const { lng, lat } = this.center;
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat));
      el.style.left = pixel.x - 16 + "px";
      el.style.top = pixel.y + "px";
    },
    lookVideo() {
      this.showVideo = true;
    },
    touchmove() {
      this.dragging = true;
    },
    touchend() {
      this.dragging = false;
    },
    goBack() {
      if (sessionStorage.getItem("houseType") == 'true') {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    },
    //跳转到搜索页面
    gohouseList() {
      this.$router.push("/SecondHouseList");
    },
    //预约看房

    orderhouse() {
      if (this.isLogin()) {
        this.$router.push({
          path: "/OrderHouse",
          query: {
            id: this.item01.id,
            title: this.item01.title,
            type: "second",
          },
        });
      }
    },

  
    onChange() {
      if (this.houseVedio && !this.$refs.houseVedio.paused) {
        this.$refs.houseVedio.pause()
      }
    },
    // playVideo() {
    //   if (this.houseVedio && this.$refs.houseVedio.paused) {
    //     this.$refs.houseVedio.play();
    //   } else if (this.houseVedio && !this.$refs.houseVedio.paused) {
    //     this.$refs.houseVedio.pause();
    //     this.showPlay=0;
    //   }
    // },
    //跳转到详情页
    goBuildingDetail(houseId) {
      this.id = houseId;
      this.getHouseDetail();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      // location. reload()
    },
    // 跳转到二手房列表
    goBuildingList() {
      this.$router.push({
        path: "/secondHouseDetail",
      });
    },
    // 跳转到相册
    goAlbum() {
      // this.$router.push({
      //   path: "/buildingAlbum",
      //   query: {
      //     id: this.id,
      //   },
      // });
    },
    collectClick() {
      // this.collect = !this.collect;
      console.log("获取id" + this.id + "zzzz" + this.collect);
      if (this.isLogin()) {
        const data = {
          //楼盘id
          id: this.id,
          //是否收藏
          collect: this.collect,
        };
        this.$post("secondHouse/secondHouseCollection", data).then((res) => {
          if (res.status == 200) {
            this.collect = !this.collect;
            this.$toast.success(res.msg);
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }
    },
    getHouseDetail() {
      const data = {
        id: this.id,
      };
      this.$post("secondHouse/getSecondHouseDetail", data).then((res) => {
        if (res.status == 200) {
          this.updateTime = res.data.updateTime;
          this.item01 = res.data.secondHouse;
          this.item01.title = escapeHtml(res.data.secondHouse.title)
          this.swipeList = res.data.images;
          this.houseList = res.data.secondHouseList;
          this.copyright = res.data.codeMap.copyright;
          this.copyrightHref = res.data.codeMap.jump_link;
          this.annotation = res.data.codeMap.exemption_content;
          this.labelList = res.data.secondHouse.tags.split(",");
          this.collect = res.data.collect;

          this.center = {
            lng: res.data.secondHouse.lng ? res.data.secondHouse.lng : "",
            lat: res.data.secondHouse.lat ? res.data.secondHouse.lat : "",
          };
          this.houseVedio = res.data.secondHouse.houseVedio;
          this.$getShareInfo(this.item01.title,this.swipeList[0]);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
  components: {
    houseItem,
    BaiduMap,
    BmInfoWindow,
    BmOverlay,
  },
  mounted() {
    this.getHouseDetail();
  },


};
</script>
<style lang="less" scoped>

.housedetail {
  background-color: #f7f7f7;
  .bm-view {
    width: 100%;
    height: 210px;
  }
  .detail-top {
    position: relative;

    /deep/.van-swipe-item {
      height: 210px;
      img {
        width: 100%;
        vertical-align: middle;
        height: 210px;
      }
    }
    .icon-center {
      // width: 50px;
      // height: 50px;
      // border-radius: 50%;
      // background: rgba(0, 0, 0, 0.3);
      // display: flex;
      // align-items: center;
      // justify-content: center;
      position: absolute;
      top: 90px;
      right: 160px;
      .van-icon {
        color: #e5e4e2;
        font-size: 60px;
      }
    }
    .icon-box {
      position: absolute;
      display: flex;
      justify-content: space-between;
      top: 0;
      left: 0;
      right: 0;
      padding: 12px;

      .icon-item {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;

        .van-icon {
          color: #e5e4e2;
          font-size: 20px;
        }
      }

      .icon-right {
        display: flex;

        .icon-item {
          margin-left: 10px;
        }
      }
    }

    .browse {
      position: absolute;
      bottom: 12px;
      right: 12px;
      color: #fff;
      font-size: 15px;
    }
  }
  .detail-middle {
    background-color: #fff;
    padding: 0 15px;
    margin-bottom: 15px;

    .middle-name {
      padding: 15px 0;
      margin: 10px 0;
      color: #333;
      font-size: 17px;
      border-bottom: 1px solid #eeeeee;

      span {
        margin-right: 10px;
      }
    }

    .middle-msg {
      padding: 10px 0;
      border-bottom: 1px solid #eeeeee;

      /deep/ .van-grid-item {
        border-right: 1px solid #eeeeee;

        .van-grid-item__content {
          padding: 5px 0;
        }

        &:last-child {
          border-right: 0;
        }
      }

      p {
        color: #858585;
        font-size: 14px;
      }

      span {
        color: #e5513f;
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }

  /deep/.van-cell {
    padding: 0;
  }
  /deep/.van-overlay {
    background: #000000;
  }
  .video-content /deep/.van-popup {
    display: flex;
    justify-content: center;
    width: 100%;
    // height: 210px;
  }
  .video-content {
    .van-icon {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -200px;
      left: 330px;
    }
    video {
      width: 375px;
      // height: 210px;
    }
  }
  .middle-content {
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
    color: #878787;
    .middle-label {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      span {
        display: block;
        padding: 2px 5px;
        font-size: 13px;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 3px;
        &:nth-child(4n + 1) {
          color: #ff7a4d;
          background: #ffece8;
        }
        &:nth-child(4n + 2) {
          color: #00c482;
          background: #e1f5ed;
        }
        &:nth-child(4n + 3) {
          color: #ffa54c;
          background: #fdf1e0;
        }
        &:nth-child(4n + 4) {
          color: #00bfff;
          background: #e1ffff;
        }
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        height: 30px;
        width: 50%;
        display: flex;
        font-size: 15px;

        em {
          color: #878787;
          display: block;
          width: 40px;
          flex-shrink: 0;
          font-size: 15px;
        }

        span {
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .detail-map {
    background-color: white;
    &.detail-map {
      padding: 0;
      /deep/.BMap_bubble_content,
      /deep/.BMap_bubble_title {
        color: #000;
        font-size: 12px;
        text-overflow: ellipsis;
      }
      .sample {
        width: 32px;
        height: 32px;
        position: absolute;
      }
    }
  }
  .item-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 15px;
    .title-left {
      color: #666;
      font-size: 15px;
      padding-left: 15px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 14px;
        border-radius: 2px;
        background-color: #09d5c6;
      }
    }

    .more {
      color: #888;
      font-size: 14px;
    }
  }
  .detail-periphery {
    .box-item {
      .box-content {
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
  .detail-introduce {
    background-color: white;
    margin-bottom: 10px;
    .introduce-box {
      font-size: 16px;
      color: #444444;
      font-family: "微软雅黑";
      padding: 0 15px;
      position: relative;
    }
  }
  .detail-periphery {
    background-color: white;
    margin-bottom: 10px;
  }
  .detail-annotation {
    font-size: 14px;
    color: rgb(165, 165, 165);
    height: 250px;
    .annotation-content {
      padding: 15px;
    }
    .copyright {
      padding: 15px 15px env(safe-area-inset-bottom);
      text-align: center;
      font-size: 14px;
      a {
        color: #aaaaaa;
      }
    }
  }

  .detail-bottom {
    padding: 5px 30px env(safe-area-inset-bottom);
    border-top: 1px solid #ddd;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    background-color: #fff;
    height: 60px;
    box-sizing: border-box;
    z-index: 999;
    .bottom-btn {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      background-color: #fca448;
      border-radius: 5px;
    }
  }

  /deep/ .van-popup {
    width: 80%;
    overflow-y: unset;

    .price-box,
    .opening-box {
      padding: 20px 10px;
      position: relative;
      background: #f6f6f6;
      border-radius: 10px;
      text-align: center;

      .price-logo {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -25px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }

      .price-title {
        margin: 5px 0 10px;
        color: #333;
        font-size: 20px;
      }

      .price-text {
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
      }

      .opening-text {
        line-height: 50px;
      }

      input {
        border: 0;
        outline: unset;
        background: #f6f6f6;
        width: 100%;
        padding: 10px 5px;
        height: 60px;
        box-sizing: border-box;
        border-bottom: 1px solid #ddd;
        font-size: 20px;
      }

      .van-checkbox {
        height: 50px;

        .van-checkbox__label {
          font-size: 14px;
          color: #878787;

          span {
            color: #788796;
            font-size: 15px;
          }
        }
      }

      .price-btn {
        display: flex;
        margin: 10px 0;

        div {
          flex: 1;
          margin: 0 10px;
          font-size: 17px;
          height: 34px;
          line-height: 34px;
          box-sizing: border-box;
          border-radius: 5px;
        }

        .btn-cancel {
          color: #555;
          background: #fff;
          border: 1px solid #ddd;
        }

        .btn-sure {
          color: #fff;
          background: #00d3c4;
        }
      }
    }
  }

  /deep/ .van-popup.agree {
    width: 100%;
    overflow-y: auto;

    .agree-content {
      padding: 20px;
      width: 100%;
      background: #fff;
      box-sizing: border-box;
      min-height: 100vh;
      padding-bottom: 100px;
    }
  }

  .agree-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: #fff;

    /deep/ .van-checkbox {
      height: 44px;
      padding-left: 20px;

      .van-checkbox__label {
        font-size: 14px;
        color: #000;
      }
    }

    .agree-btn {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: #00d3c4;
      font-size: 18px;
    }
  }
}
</style>